import React from 'react'

import { useTranslation } from 'react-i18next';

import globePattern from 'assets/image/patterns/pattern-1.png';
import slide from 'assets/image/l2/shopping.webp';
import slide2 from 'assets/image/l2/slide.webp';

export function Slider() {

    const { t } = useTranslation()

    const rand = Math.floor(Math.random() * 2) + 1

    return (
        <div className="position-relative z-index-1 bg-squeeze pt-26 light-mode-texts">
            <div className="pos-abs-tr h-100">
                <img src={globePattern} alt="" className="h-100" />
            </div>
            <div className="container position-static">
                <div className="row position-relative align-items-center position-static">
                    <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-12 pt-lg-13 pb-lg-33 pb-xl-34 pb-10">
                        <div className="row">
                            <div className="col-xxl-12 col-xl-7 col-md-12 col-sm-12">
                                <div className="text-main-orng font-size-5 font-weight-semibold mb-7">
                                    {t('slider:counter')}
                                </div>
                                <h1 className="font-size-11 mb-9 text-white">{t('slider:textOne')}</h1>
                                <p className="font-size-5 text-main-orng">{t('slider:textTwo')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-5 col-xl-4 col-lg-3 col-md-4 col-sm-6 col-xs-6 col-8 pos-abs-br position-static position-md-absolute mx-auto ml-md-auto d-none d-xl-block">
                        <img src={rand === 1 ? slide2 : slide} alt="" className="main-slider-image w-100" />
                    </div>
                </div>
            </div>
        </div>
    )
}
