import React from 'react'
import { useTranslation } from 'react-i18next';

import boyner from 'assets/image/partners/boyner.png';
import gittigidiyor from 'assets/image/partners/gittigidiyor.png';
import hepsiburada from 'assets/image/partners/hepsiburada.png';
import morhipo from 'assets/image/partners/morhipo.png';
import n11 from 'assets/image/partners/n11.png';
import trendyol from 'assets/image/partners/trendyol.png';

export function Stores() {
  const { t } = useTranslation()
  return (
    <div className="pt-11 pt-lg-26 pb-lg-16" data-aos="fade-left" data-aos-duration="800" data-aos-delay="400" data-aos-once="true">
      <div className="container">
        <div className="row align-items-center pb-14">
          <div className="col-12 col-lg-6">
            <div className="text-center text-lg-left mb-13 mb-lg-0">
              <h2 className="font-size-9 font-weight-bold">{t('stores:header')}</h2>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="text-center text-lg-right">
              <a className="btn btn-outline-orange text-uppercase" href="/stores">{t('stores:showMore')}</a>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-6 col-xl-2 col-lg-2 col-sm-6 col-xs-6">
            <a href="https://www.boyner.com.tr/" target="_blank" rel="noreferrer" className="bg-white border border-color-2 rounded-4 text-center px-10 py-10 hover-shadow-1 mb-9 d-block w-100">
              <img src={boyner} alt="" className="w-100" />
            </a>
          </div>
          <div className="col-6 col-xl-2 col-lg-2 col-sm-6 col-xs-6">
            <a href="https://www.gittigidiyor.com/" target="_blank" rel="noreferrer" className="bg-white border border-color-2 rounded-4 text-center px-10 py-10 hover-shadow-1 mb-9 d-block w-100">
              <img src={gittigidiyor} alt="" className="w-100" />
            </a>
          </div>
          <div className="col-6 col-xl-2 col-lg-2 col-sm-6 col-xs-6">
            <a href="https://www.hepsiburada.com/" target="_blank" rel="noreferrer" className="bg-white border border-color-2 rounded-4 text-center px-10 py-10 hover-shadow-1 mb-9 d-block w-100">
              <img src={hepsiburada} alt="" className="w-100" />
            </a>
          </div>
          <div className="col-6 col-xl-2 col-lg-2 col-sm-6 col-xs-6">
            <a href="https://www.morhipo.com/" target="_blank" rel="noreferrer" className="bg-white border border-color-2 rounded-4 text-center px-10 py-10 hover-shadow-1 mb-9 d-block w-100">
              <img src={morhipo} alt="" className="w-100" />
            </a>
          </div>
          <div className="col-6 col-xl-2 col-lg-2 col-sm-6 col-xs-6">
            <a href="https://www.n11.com/" target="_blank" rel="noreferrer" className="bg-white border border-color-2 rounded-4 text-center px-10 py-10 hover-shadow-1 mb-9 d-block w-100">
              <img src={n11} alt="" className="w-100" />
            </a>
          </div>
          <div className="col-6 col-xl-2 col-lg-2 col-sm-6 col-xs-6">
            <a href="https://www.trendyol.com/" target="_blank" rel="noreferrer" className="bg-white border border-color-2 rounded-4 text-center px-10 py-10 hover-shadow-1 mb-9 d-block w-100">
              <img src={trendyol} alt="" className="w-100" />
            </a>
          </div>
          <div className="col-6 col-xl-2 col-lg-2 col-sm-6 col-xs-6">
            <a href="https://www.trendyol.com/" target="_blank" rel="noreferrer" className="bg-white border border-color-2 rounded-4 text-center px-10 py-10 hover-shadow-1 mb-9 d-block w-100">
              <img src={trendyol} alt="" className="w-100" />
            </a>
          </div>
          <div className="col-6 col-xl-2 col-lg-2 col-sm-6 col-xs-6">
            <a href="https://www.trendyol.com/" target="_blank" rel="noreferrer" className="bg-white border border-color-2 rounded-4 text-center px-10 py-10 hover-shadow-1 mb-9 d-block w-100">
              <img src={trendyol} alt="" className="w-100" />
            </a>
          </div>

        </div>
      </div>
    </div>
  )
}
