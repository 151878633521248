import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Form } from 'antd';

// import { messages } from 'utils/messages';

const { Item } = Form;

export function FormItem({
  children,
  validateStatus,
  help = '',
  auto = false,
  ...rest
}) {
  const status = help && !validateStatus ? 'error' : '';

  return (
    <StyledItem
      colon={false}
      validateStatus={status}
      {...rest}
      help={help}
      auto={auto ? 'auto' : undefined}
    >
      {children}
    </StyledItem>
  );
}

FormItem.propTypes = {
  children: PropTypes.any,
  validateStatus: PropTypes.string,
  marginbottom: PropTypes.string,
  help: PropTypes.string,
  auto: PropTypes.bool,
};

const StyledItem = styled(Item)`
  position: relative;
  margin-bottom: ${({ marginbottom }) => marginbottom || '24px'};

  .ant-form-item-label {
    line-height: unset;
  }

  .ant-form-item-control {
    height: ${props => (props.auto ? 'auto' : '40px')};
    line-height: unset;
  }

  label {
    line-height: 16px;
    font-size: 14px;
    color: #323130;
    margin-bottom: 8px;
    font-weight: 600;
    display: block;
  }

  input.ant-input-lg {
    font-size: 14px;
  }

  .ant-form-explain {
    height: 16px;
    display: block;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
  }
`;
