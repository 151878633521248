import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { cookies } from 'utils';
import { cookieAuthKey } from 'api/api-client';
import { Loading } from './components';

const token = cookies.get(cookieAuthKey);

const Main = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/Main')
);
const Register = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/Register')
);
const Login = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/Login')
);
const Forget = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/Recovery')
);
const About = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/About')
);
const Stores = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/Stores')
);
const Plans = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/Plans')
);
const Contacts = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/Contacts')
);
const Dashboard = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/Dashboard')
);
const Orders = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/Orders')
);
const AddOrder = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/Orders/add')
);
const Packages = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/Packages')
);
const Wallet = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/Wallet')
);
const Addreses = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/Addreses')
);
const Profile = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/Profile')
);
const NotFound = React.lazy(() =>
    import(/* webpackChunkName: "Main" */ './containers/NotFound')
)

function Routes() {
    
    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                <Route
                    exact
                    path="/"
                    render={props => <Main {...props} />}
                />
                <Route
                    exact
                    path="/about"
                    render={props => <About {...props} />}
                />
                <Route
                    exact
                    path="/stores"
                    render={props => <Stores {...props} />}
                />
                <Route
                    exact
                    path="/plans"
                    render={props => <Plans {...props} />}
                />
                <Route
                    exact
                    path="/contacts"
                    render={props => <Contacts {...props} />}
                />
                <Route
                    exact
                    path="/register"
                    render={props => (
                        !token ? (<Register {...props} />) : (<Redirect to="/dashboard" />)
                    )}
                />
                <Route
                    exact
                    path="/login"
                    render={props => (
                        !token ? (<Login {...props} />) : (<Redirect to="/dashboard" />)
                    )}
                />
                <Route
                    exact
                    path="/recovery"
                    render={props => (
                        !token ? (<Forget {...props} />) : (<Redirect to="/dashboard" />)
                    )}
                />

                <Route
                    exact
                    path="/dashboard"
                    render={props => (
                        token ? (<Dashboard {...props} />) : ( <Redirect to="/login" />)
                    )}
                />
                <Route
                    exact
                    path="/orders"
                    render={props => (
                        token ? (<Orders {...props} />) : ( <Redirect to="/login" />)
                    )}
                />
                <Route
                    exact
                    path="/orders/add"
                    render={props => (
                        token ? (<AddOrder {...props} />) : ( <Redirect to="/login" />)
                    )}
                />
                <Route
                    exact
                    path="/packages"
                    render={props => (
                        token ? (<Packages {...props} />) : ( <Redirect to="/login" />)
                    )}
                />
                <Route
                    exact
                    path="/wallet"
                    render={props => (
                        token ? (<Wallet {...props} />) : ( <Redirect to="/login" />)
                    )}
                />
                <Route
                    exact
                    path="/addreses"
                    render={props => (
                        token ? (<Addreses {...props} />) : ( <Redirect to="/login" />)
                    )}
                />
                <Route
                    exact
                    path="/profile"
                    render={props => (
                        token ? (<Profile {...props} />) : ( <Redirect to="/login" />)
                    )}
                />
                <Route component={NotFound} />
            </Switch>
        </Suspense>
    );
}

export default Routes;