import React from 'react'
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

export const PriceList = () => {
    const { t } = useTranslation()
    return (
        <div className="bg-default-2">
            <div className="container">
                <div className="py-20 px-10 px-xs-5">
                    <div className="text-center mb-12 mb-lg-17">
                        <h2 className="font-size-10 font-weight-bold mb-8">{t('priceList:heading')}</h2>
                        <p className="font-size-5 px-5 px-lg-7 px-xl-9 px-xxl-15 mb-6">{t('priceList:notice')}</p>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <h4 className="mb-5 font-size-6">{t('priceList:blockOneHeader')}</h4>

                            <ul className="list-unstyled">
                                <li>
                                    <div className="bg-white px-6 py-4 rounded-4 d-flex justify-content-between align-items-center font-weight-semibold font-size-4 mb-5">
                                        {t('priceList:blockOnetextOne')}
                                        <Price>$ 1.00</Price>
                                    </div>
                                </li>
                                <li>
                                    <div className="bg-white px-6 py-4 rounded-4 d-flex justify-content-between align-items-center font-weight-semibold font-size-4 mb-5">
                                        {t('priceList:blockOnetextTwo')}
                                        <Price>$ 2.50</Price>
                                    </div>
                                </li>
                                <li>
                                    <div className="bg-white px-6 py-4 rounded-4 d-flex justify-content-between align-items-center font-weight-semibold font-size-4 mb-5">
                                        {t('priceList:blockOnetextTree')}
                                        <Price>$ 3.00</Price>
                                    </div>
                                </li>
                                <li>
                                    <div className="bg-white px-6 py-4 rounded-4 d-flex justify-content-between align-items-center font-weight-semibold font-size-4 mb-5">
                                        {t('priceList:blockOnetextFour')}
                                        <Price>$ 3.60</Price>
                                    </div>
                                </li>
                                <li>
                                    <div className="bg-white px-6 py-4 rounded-4 d-flex justify-content-between align-items-center font-weight-semibold font-size-4 mb-5">
                                        {t('priceList:blockOnetextFive')}
                                        <Price>$ 3.60</Price>
                                    </div>
                                </li>
                                <li>
                                    <div className="bg-white px-6 py-4 rounded-4 d-flex justify-content-between align-items-center font-weight-semibold font-size-4 mb-5">
                                        {t('priceList:blockOnetextSix')}
                                        <Price>$ 3.60</Price>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12 mt-xs-50">
                            <h4 className="mb-5 font-size-6">{t('priceList:blockTwoHeader')}</h4>

                            <ul className="list-unstyled">
                                <li>
                                    <div className="bg-white px-6 py-4 rounded-4 d-flex justify-content-between align-items-center font-weight-semibold font-size-4 mb-5">
                                        {t('priceList:blockTwotextOne')}
                                        <Price>$ 2.00</Price>
                                    </div>
                                </li>
                                <li>
                                    <div className="bg-white px-6 py-4 rounded-4 d-flex justify-content-between align-items-center font-weight-semibold font-size-4 mb-5">
                                        {t('priceList:blockTwotextTwo')}
                                        <Price>$ 3.50</Price>
                                    </div>
                                </li>
                                <li>
                                    <div className="bg-white px-6 py-4 rounded-4 d-flex justify-content-between align-items-center font-weight-semibold font-size-4 mb-5">
                                        {t('priceList:blockTwotextTree')}
                                        <Price>$ 4.50</Price>
                                    </div>
                                </li>
                                <li>
                                    <div className="bg-white px-6 py-4 rounded-4 d-flex justify-content-between align-items-center font-weight-semibold font-size-4 mb-5">
                                        {t('priceList:blockTwotextFour')}
                                        <Price>$ 5.00</Price>
                                    </div>
                                </li>
                                <li>
                                    <div className="bg-white px-6 py-4 rounded-4 d-flex justify-content-between align-items-center font-weight-semibold font-size-4 mb-5">
                                        {t('priceList:blockTwotextFive')}
                                        <Price>$ 5.00</Price>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Price = styled.div`
    background-color: #FDB928;
    color: #602086;
    padding: 5px 15px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
`;
