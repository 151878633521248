import React from 'react'
import { Modal, Button, Form, Input, Row, Col, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemSize, phoneNumberPrefixOptions, gender as genderList, svSerias, requiredRule, layout } from 'utils';

export const InvoiceModal = ({
    show = false,
    setModalShow,
    packageId,
    ...rest
}) => {

    const { t } = useTranslation()

    const [form] = Form.useForm()

    const handleOk = () => {
        // setIsModalVisible(false);
    };

    const handleCancel = () => {
        setModalShow(false)
    };

    const onFinish = (values) => {

    };

    return (
        <Modal title="Hesab faktura" visible={show} onOk={handleOk} onCancel={handleCancel}>
            <Form
                {...layout}
                name="basic"
                onFinish={onFinish}
                hideRequiredMark
                form={form}
            >
                <Row gutter={24}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item
                            label="Ad"
                            name="name"
                            rules={[requiredRule]}
                        >
                            <Input size={formItemSize} placeholder="Adınızı daxil edin" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item
                            label="Ünvan"
                            name="address"
                            rules={[requiredRule]}
                        >
                            <Input size={formItemSize} placeholder="Yaşayış ünvanını daxil edin" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}