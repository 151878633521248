import React, { forwardRef } from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/az_AZ';
import 'moment/locale/az';

export const DatePicker = forwardRef((props, ref) => (
  <AntDatePicker
    ref={ref}
    size="large"
    allowClear={false}
    showToday={false}
    dropdownClassName="dropdownDatePickerClassName"
    getCalendarContainer={trigger => trigger.parentNode}
    format="DD-MM-YYYY"
    locale={locale}
    style={{ display: 'block' }}
    {...props}
  />
));
