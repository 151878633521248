import React from 'react'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const Button = ({
    loading,
    onClick,
    block,
    type = 'button',
    styles = '',
    children,
    ...rest
}) => {
    
    const antIcon = <LoadingOutlined spin style={{ color: '#FFBA00', marginRight: '12px'}} />

    return (
        <button
            {...rest}
            type={type}            
            className={`btn ${styles} ${block ? 'btn-block' : null}`}
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            onClick={onClick}
        >
            {loading ? (<Spin indicator={antIcon} style={{lineHeight: 0}} size="small" />) : null}
            <div>{children}</div>
        </button>
    )
}