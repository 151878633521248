import { useEffect } from 'react';

import { cookies } from 'utils';

export function useLoggedCallback(callback = () => {}) {
  useEffect(() => {
    const hasToken = !!cookies.get('_TKN_');

    if (hasToken) {
      callback();
    }
  }, [callback]);
}
