import React from 'react'
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Calc from './calculator';

const { Paragraph } = Typography;

export function Calculator() {
    const { t } = useTranslation()
    return (
        <div className="bg-default-2 pt-11 pt-lg-20 pb-7 pb-lg-18">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <h3 className="font-size-9 mt-8 font-weight-semibold text-main-purp">{t('calculator:attention')}</h3>
                        <div className="font-size-4 mb-5">{t('calculator:textOne')}</div>
                        <div className="font-size-4 mb-5">{t('calculator:textTwo')}</div>
                        <Paragraph className="d-flex align-items-center">
                            <pre className="d-inline-block mr-5 text-main-purp font-weight-semibold">
                                {t('calculator:textTree')}
                            </pre>
                            <span className="font-size-4">{t('calculator:textFour')}</span>
                        </Paragraph>
                        <Paragraph>
                            <pre className="d-inline-block text-main-purp font-weight-semibold">
                                {t('calculator:textFive')}
                            </pre>
                        </Paragraph>
                    </div>
                    <div className="col-12 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                        <Calc />
                    </div>
                </div>
            </div>
        </div>
    )
}
