export const formItemSize = 'large';

export const phoneNumberPrefixOptions = [
    {
        // az
        id: 0, // array index
        name: '+994',
        mask: '11 111-11-11',
        length: 9, // phone number length without prefix
    },
    {
        // ru
        id: 1,
        name: '+7',
        mask: '(111) 111-11-11',
        length: 10,
    },
];

export const gender = [
    {
        id: 1,
        name: 'Kişi'
    },
    {
        id: 2,
        name: 'Qadın'
    }
]

export const svSerias = [
    {
        id: 'AZE',
    },
    {
        id: 'AA',
    }
]

export const requiredRule = {
    required: true,
    message: 'Bu dəyəri qeyd etməniz vacibdir.',
};

export const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

export const orderStatuses = {
    1: 'Yeni',
    2: 'Təsdiqlənib',
    3: 'Sifariş edilib',
    4: 'Bağlamalara əlavə edilib',
    5: 'Çatdırılıb',
    6: 'İmtina edilib'
};

export const orderPaymentStatus = {
    1: 'Ödənilib',
    2: 'Ödəniş olunmayıb',    
};

export const textColors = {
    blue: 'text-blue ',
    eastern: 'text-eastern ',
    denim: 'text-denim ',
    dodger: 'text-dodger ',
    dodger2: 'text-dodger-2 ',
    spray: 'text-spray ',
    turquoise: 'text-turquoise ',
    regent: 'text-regent ',
    coral: 'text-coral ',
    orange: 'text-orange ',
    orange2: 'text-orange-2 ',
    red: 'text-red ',
    red2: 'text-red-2 ',
    yellow: 'text-yellow ',
    yellow2: 'text-yellow-2 ',
    casablanca: 'text-casablanca ',
    indigo: 'text-indigo ',
    shamrock: 'text-shamrock ',
    black: 'text-black ',
    black2: 'text-black-2 ',
    gray: 'text-gray ',
    smoke: 'text-smoke ',
    pink: 'text-pink ',
    violet: 'text-violet ',
    mercury: 'text-mercury ',
    ebonyClay: 'text-ebony-clay ',
    hitGray: 'text-hit-gray ',
    gallery: 'text-gallery ',
    squeeze: 'text-squeeze ',
    helio: 'text-helio ',
    athens: 'text-athens ',
    concrete: 'text-concrete ',
    allports: 'text-allports ',
    marino: 'text-marino ',
    poppy: 'text-poppy ',
    polar: 'text-polar ',
    eggBlue: 'text-egg-blue ',
    conch: 'text-conch ',
    body: 'text-body ',
    muted: 'text-muted ',
    black50: 'text-black-50 ',
    white50: 'text-white-50 ',
};

export const bgColors = {
    blue: 'bg-blue-opacity-1',
    eastern: 'bg-eastern-opacity-1',
    denim: 'bg-denim-opacity-1',
    dodger: 'bg-dodger-opacity-1',
    dodger2: 'bg-dodger-2-opacity-1',
    spray: 'bg-spray-opacity-1',
    turquoise: 'bg-turquoise-opacity-1',
    regent: 'bg-regent-opacity-1',
    coral: 'bg-coral-opacity-1',
    orange: 'bg-orange-opacity-1',
    orange2: 'bg-orange-2-opacity-1',
    red: 'bg-red-opacity-1',
    red2: 'bg-red-2-opacity-1',
    yellow: 'bg-yellow-opacity-1',
    yellow2: 'bg-yellow-2-opacity-1',
    casablanca: 'bg-casablanca-opacity-1',
    indigo: 'bg-indigo-opacity-1',
    shamrock: 'bg-shamrock-opacity-1',
    black: 'bg-black-opacity-1',
    black2: 'bg-black-2-opacity-1',
    gray: 'bg-gray-opacity-1',
    smoke: 'bg-smoke-opacity-1',
    pink: 'bg-pink-opacity-1',
    violet: 'bg-violet-opacity-1',
    mercury: 'bg-mercury-opacity-1',
    ebonyClay: 'bg-ebony-clay-opacity-1',
    hitGray: 'bg-hit-gray-opacity-1',
    gallery: 'bg-gallery-opacity-1',
    squeeze: 'bg-squeeze-opacity-1',
    helio: 'bg-helio-opacity-1',
    athens: 'bg-athens-opacity-1',
    concrete: 'bg-concrete-opacity-1',
    allports: 'bg-allports-opacity-1',
    marino: 'bg-marino-opacity-1',
    poppy: 'bg-poppy-opacity-1',
    polar: 'bg-polar-opacity-1',
    eggBlue: 'bg-egg-blue-opacity-1',
    conch: 'bg-conch-opacity-1',
    body: 'bg-body-opacity-1',
    muted: 'bg-muted-opacity-1',
    black50: 'bg-black-50-opacity-1',
    white50: 'bg-white-50-opacity-1',
};