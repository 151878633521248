/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Select as AntSelect } from 'antd';
import styled from '@emotion/styled';
import { RiArrowDownSLine } from 'react-icons/ri'

const { Option } = AntSelect;

export const SelectWithoutMemo = forwardRef(function CustomSelect(props, ref) {
  const {
    data = [],
    keys = ['name'],
    placeholder,
    showFirstEmptyOption = false,
    notFoundContent = 'Məlumat tapılmadı',
    loading = false,
    disabled = false,
    size = 'large', // large used in most cases
    getPopupContainer = trigger => trigger.parentNode,
    ...rest
  } = props;

  
  return (
    <StyledSelect {...rest}>
      <AntSelect
        {...rest}
        loading={loading}
        notFoundContent={notFoundContent}
        dropdownClassName="selectDropdownClassName"
        optionFilterProp="children"
        getPopupContainer={getPopupContainer}
        suffixIcon={!loading && <RiArrowDownSLine />}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={disabled || loading}
        size={size}
        ref={ref}
        placeholder={placeholder || 'Seçin'}
      >
        {showFirstEmptyOption && <Option value="all">Hamısı</Option>}
        {data.map((element, index) => (
          <Option
            key={`${element.id || ''}${index}`}
            disabled={element.disabled}
            value={element?.id}
          >
            {`${element[keys[0]]} ${element[keys[1]] || ''} ${element[
              keys[2]
            ] || ''}`}
          </Option>
        ))}
      </AntSelect>
    </StyledSelect>
  );
});

export const Select = React.memo(
  SelectWithoutMemo,
  (prevProps, nextProps) =>
    prevProps.data &&
    prevProps.data.length === nextProps.data &&
    nextProps.data.length &&
    prevProps.value === nextProps.value &&
    prevProps.hasError === nextProps.hasError &&
    !prevProps.notUseMemo
);

Select.propTypes = {
  hasError: PropTypes.bool,
  showSearch: PropTypes.bool,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  data: PropTypes.array,
  keys: PropTypes.array,
  showFirstEmptyOption: PropTypes.bool,
};

Select.defaultProps = {
  showSearch: false,
  loading: false,
  placeholder: '',
  onSelect: () => {},
  data: [],
  keys: ['name'],
  showFirstEmptyOption: false,
};

const StyledSelect = styled.div`
  text-align: left;

  .ant-select {
    font-size: 14px;
    width: ${props => props.width || '100%'} !important;
  }
`;

// eslint-disable-next-line react/no-multi-comp
export const MultiSelect = forwardRef(function(props, ref) {
  const { data, mode = 'multiple', ...rest } = props;

  return (
    <AntSelect
      mode={mode}
      size="large"
      notFoundContent={null}
      ref={ref}
      {...rest}
    >
      {data.map(item => (
        <Option
          key={String(item.id)}
          value={mode === 'multiple' ? String(item.id) : item.name}
        >
          {item.name}
        </Option>
      ))}
    </AntSelect>
  );
});
