import React from 'react'
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin } from "react-icons/ai";
import { useTranslation } from 'react-i18next';

export function Footer() {
    const { t } = useTranslation()
    return (
        <footer className="footer bg-ebony-clay dark-mode-texts">
            <div className="container pt-8">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="media">
                            <div className="media-body">
                                <ul>
                                    <li>
                                        <a className="mb-0 font-size-4 font-weight-bold" href="mailto:support@bex.az">support@bex.az</a>
                                    </li>
                                    <li>
                                        <a className="mb-0 font-size-4 font-weight-bold" href="/">+994 12 3101212</a>
                                    </li>
                                    <li>
                                        <a className="mb-0 font-size-4 font-weight-bold" href="/">+994 77 2775677</a>
                                    </li>
                                    <li>
                                        <a className="mb-0 font-size-4 font-weight-bold" href="/">+994 50 2222222</a>
                                    </li>
                                    <li>
                                        {t('footer:address')}
                                    </li>
                                    <li>
                                        {t('footer:workHours')}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
                    <div className="col-lg-4 col-md-6 col-sm-3 col-xs-6">
                        <div className="social-icons float-right">
                            <ul className="pl-0 list-unstyled d-flex align-items-end ">
                                <li className="d-flex flex-column justify-content-center px-3 mr-3">
                                    <a href="https://facebook.com" target="_blank" rel="noreferrer" className="hover-color-primary heading-default-color">
                                        <AiFillFacebook />
                                    </a>
                                </li>
                                <li className="d-flex flex-column justify-content-center px-3 mr-3">
                                    <a href="https://instagram.com" target="_blank" rel="noreferrer" className="hover-color-primary heading-default-color">
                                        <AiFillInstagram />
                                    </a>
                                </li>
                                <li className="d-flex flex-column justify-content-center px-3 mr-3">
                                    <a href="https://linkedin.com" target="_blank" rel="noreferrer" className="hover-color-primary heading-default-color">
                                        <AiFillLinkedin />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="copyright">
                            {t('footer:copyright')}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
