import React from 'react'

export function Layout({ children }) {
    return (
        <div className="site-wrapper overflow-hidden">
            {children}
        </div>
    )
}

export function CabinetLayout({ children }) {
    return (
        <div className="site-wrapper overflow-hidden bg-default-2">
            {children}
        </div>
    )
}