import React from 'react';
// api
import { useApi, useLoggedCallback } from 'hooks';
import { fetchUserInfo } from 'api/user-client';

const UserContext = React.createContext(null);

function UserProvider(props) {
  const { data = {}, isLoading, isFulfilled, run } = useApi({
    deferFn: fetchUserInfo,
  });

  useLoggedCallback(run);

  const value = { ...data, isLoading, isFulfilled, run };

  return <UserContext.Provider value={value} {...props} />;
}

function useUser() {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }

  return context;
}

export { UserProvider, useUser };
