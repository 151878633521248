import React from 'react'
import styled from '@emotion/styled';
import { Drawer as AntDrawer, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { cookies } from 'utils';

import { cookieAuthKey } from 'api/api-client';
import { logout } from 'api/auth-client';
import { useUser } from 'context/user-context';

import { FaRegUserCircle, RiLogoutCircleRLine } from 'react-icons/all';

import { GrFormClose } from 'react-icons/gr';



export function Drawer({ show = false, toggler }) {
    
    const token = cookies.get(cookieAuthKey);

    const {
        name,
        surname,
        isLoading
    } = useUser();

    return (
        <AntDrawer            
            placement="right"
            closable={false}
            visible={show}
            width={'85vw'}
        >
            <DrawerHeader>
                {token ? (
                    <User>
                    {isLoading ? (<Spin size="small" className="purple-spinner"/>)
                    : (
                        <Link to="/dashboard" >
                            <FaRegUserCircle className="mr-2" /> {name}{' '}{surname}
                        </Link>
                    )}
                    </User>
                ) : null}
               <Closer onClick={() => toggler(false)}>
                   <GrFormClose />
                </Closer>
            </DrawerHeader>
            <DrawerBody>
                <DrawerMenu>
                    <DrawerMenuItem>
                        <Link to="/about" className="nav-link">Haqqımızda</Link>
                    </DrawerMenuItem>
                    <DrawerMenuItem>
                        <Link to="/plans" className="nav-link">Tariflər</Link>
                    </DrawerMenuItem>
                    <DrawerMenuItem>
                        <Link to="/stores" className="nav-link">Mağazalar</Link>
                    </DrawerMenuItem>
                    <DrawerMenuItem>
                        <Link to="/contacts" className="nav-link">Əlaqə</Link>
                    </DrawerMenuItem>
                </DrawerMenu>
            </DrawerBody>
            {!token ? (
            <DrawerFooter>
                <Link to="/login" className="btn btn-orng text-uppercase font-size-3 heading-default-color focus-reset">
                    Daxİl ol
                </Link>
                <Link to="/register" className="btn btn-dark text-uppercase font-size-3">
                    Qeydİyyat
                </Link>
            </DrawerFooter>
            ) : (
                <button className="btn btn-dark text-uppercase font-size-3" onClick={logout}>
                    <RiLogoutCircleRLine className="mr-7" />
                    Çıxış
                </button>
            )}
        </AntDrawer>
    )
}

const DrawerHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
`
const User = styled.div`
    a {
        font-size: 16px;
    }
`
const Closer = styled.button`
    display: block;
    width: 30px;
    height: 30px;
    border: 0;
    padding: 0;
    background: transparent;
    font-size: 30px;
    line-height: 30px;
    outline: 0;
`
const DrawerBody = styled.div`
    margin: 10px 0;
`

const DrawerMenu = styled.ul`
    margin: 50px 0;
    padding: 0 0 10px 0;
    list-style: none;
    /* border-bottom: 1px dashed #602086; */
`
const DrawerMenuItem = styled.li`
    a {
        font-size: 18px;
        color: #000 !important;
        font-weight: 600;
        padding-left: 0;
    }
`

const DrawerFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    a:first-child {
        margin-right: 10px;
    }
`