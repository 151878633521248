import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RiDashboard2Line, RiShoppingBasketFill, RiAccountCircleLine, BsCreditCard, FiBox, RiPinDistanceLine, RiArrowDownSLine } from 'react-icons/all';


export function Menu() {

    const { t } = useTranslation()

    const location = useLocation();

    const [mobileMenu, toggleMenu] = React.useState(false);

    return (
        <div className="dashboard-sidebar-wrapper container pt-md-11">
            <div className="row">
                <div className="col-12">
                    <ul className={`list-unstyled dashboard-layout-sidebar ${mobileMenu ? 'menu-show' : null}`}>
                        <li className="menu-toogler">
                            <button className="px-10 py-1 my-5 font-size-4 font-weight-semibold" onClick={() => toggleMenu(!mobileMenu)}>
                                {t('menu:menuHeader')}
                                <RiArrowDownSLine className="ml-7" />
                            </button>
                        </li>
                        <li className={location.pathname.substr(1) === 'dashboard' ? "active" : null}>
                            <Link to="/dashboard" className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center text-main-purp">
                                <RiDashboard2Line className="mr-7" />
                                {t('menu:dashboard')}
                            </Link>
                        </li>
                        <li className={location.pathname.substr(1) === 'orders' ? "active" : null}>
                            <Link to="/orders" className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center text-main-purp">
                                <RiShoppingBasketFill className=" mr-7" />
                                {t('menu:orders')}
                            </Link>
                        </li>
                        <li className={location.pathname.substr(1) === 'packages' ? "active" : null}>
                            <Link to="/packages" className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center text-main-purp">
                                <FiBox className=" mr-7" />
                                {t('menu:packages')}
                                {/* <span className="ml-5 px-1 h-1 bg-dodger text-white font-size-3 rounded-5 max-height-px-18 flex-all-center">14</span> */}
                            </Link>
                        </li>
                        <li className={location.pathname.substr(1) === 'wallet' ? "active" : null}>
                            <Link to="/wallet" className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center text-main-purp">
                                <BsCreditCard className="mr-7" />
                                {t('menu:wallet')}
                            </Link>
                        </li>
                        <li className={location.pathname.substr(1) === 'addreses' ? "active" : null}>
                            <Link to="/addreses" className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center text-main-purp">
                                <RiPinDistanceLine className="mr-7" />
                                {t('menu:addreses')}
                            </Link>
                        </li>
                        <li className={location.pathname.substr(1) === 'profile' ? "active" : null}>
                            <Link to="/profile" className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center text-main-purp">
                                <RiAccountCircleLine className="mr-7" />
                                {t('menu:profile')}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
