import React from 'react'
import { Tabs, Tooltip, Skeleton } from 'antd';
import { Clipboard } from 'components';
import usaFlag from 'assets/flags/usa.png';
import turkeyFlag from 'assets/flags/turkey.png';
import russiaFlag from 'assets/flags/russia.png';
import chinaFlag from 'assets/flags/china.png';
import germanyFlag from 'assets/flags/germany.png';
import arabianFlag from 'assets/flags/uae.png';

const { TabPane } = Tabs;

export function AddressTabs({ userInfo }) {
    return (
        <div className="bg-white rounded-4 p-10 shadow-9">
            <Tabs defaultActiveKey="1" type="card" size='small'>
                <TabPane
                    tab={<span className="d-flex align-items-center"><img src={turkeyFlag} alt="Türkiyə" /> Türkiyə</span>}
                    key="1"
                >
                    <Skeleton active loading={userInfo.isLoading}>
                        <div className="row pt-5">
                            <div className="col-12 col-lg-3 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">İsim Soyisim <Clipboard text={`${userInfo.name} ${userInfo.surname}`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">{userInfo.name} {userInfo.surname}</h5>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">İl (Şehir) <Clipboard text={`İstanbul`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">İstanbul</h5>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">TC Kimlik <Clipboard text={`55555555555`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">55555555555</h5>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">Ülkə <Clipboard text={`Türkiye`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">Türkiye</h5>
                                </div>
                            </div>
                            <div className="col-12 col-lg-9 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">Adres satır 1 <Clipboard text={`Halkalı Merkez Mahallesi, İdris Paşa Caddesi NO: 60-62B, Dükkan No: 1 /CH${userInfo.id + 100} - ${userInfo.name} ${userInfo.surname}`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">Halkalı Merkez Mahallesi, İdris Paşa Caddesi NO: 60-62B, Dükkan No: 1 /CH{userInfo.id + 100} - {userInfo.name} {userInfo.surname}</h5>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">Teslim alan kişi <Clipboard text={`Bex.az`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">Bex.az</h5>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">Semt/Mahalle <Clipboard text={`Zeytinburnu mah.`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">Zeytinburnu mah.</h5>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">ZIP/Post kodu <Clipboard text={`11304`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">11304</h5>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">Adres başlığı <Clipboard text={`Küçükçekmece`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">Küçükçekmece</h5>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">Cep telefonu <Clipboard text={`0551 277 56 77`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">0551 277 56 77</h5>
                                </div>
                            </div>
                        </div>
                    </Skeleton>
                </TabPane>
                <TabPane
                    tab={<span className="d-flex align-items-center"><img src={russiaFlag} alt="Russiya" /> Russiya</span>}
                    key="2"
                >
                    <Skeleton active loading={userInfo.isLoading}>
                        <div className="row pt-5">
                            <div className="col-12 col-lg-3 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">Фамилия, Имя, Отчество<Clipboard text={`${userInfo.name} ${userInfo.surname}`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">{userInfo.name} {userInfo.surname}</h5>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">Регион / Область <Clipboard text={`Москва`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">Москва</h5>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">Город <Clipboard text={`Москва`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">Москва</h5>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">Номер телефона <Clipboard text={`+79269315412`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">+79269315412</h5>
                                </div>
                            </div>
                            <div className="col-12 col-lg-9 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">Улица, дом, квартира <Clipboard text={`л. Генерала Белова, д. 29, офис ASE /BEX${userInfo.id + 100} - ${userInfo.name} ${userInfo.surname}`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">л. Генерала Белова, д. 29, офис ASE /BEX{userInfo.id + 100} - {userInfo.name} {userInfo.surname}</h5>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 col-md-4 col-xs-6">
                                <div className="mb-8 adress-items">
                                    <p className="font-size-3 mb-1">Почтовый индекс <Clipboard text={`123241`} /></p>
                                    <h5 className="mb-0 font-size-4 font-weight-semibold text-black-2">123241</h5>
                                </div>
                            </div>
                        </div>
                    </Skeleton>
                </TabPane>
                <TabPane
                    tab={<span className="d-flex align-items-center"><img src={chinaFlag} alt="Russiya" /> China</span>}
                    key="3"
                >
                    China
                </TabPane>
                <TabPane
                    tab={<span className="d-flex align-items-center"><img src={usaFlag} alt="USA" /> USA</span>}
                    key="4"
                >
                    Amerika
                </TabPane>
                <TabPane
                    tab={<span className="d-flex align-items-center"><img src={germanyFlag} alt="Almaniya" /> Almaniya</span>}
                    key="5"
                >
                    Almaniya
                </TabPane>
                <TabPane
                    tab={<span className="d-flex align-items-center"><img src={arabianFlag} alt="BƏƏ" /> BƏƏ</span>}
                    key="5"
                >
                    BƏƏ
                </TabPane>
            </Tabs>
        </div>
    )
}
