import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { cookies } from 'utils';
import { cookieAuthKey } from 'api/api-client';
import { useUser } from 'context/user-context';

import { RiDashboard2Line, RiShoppingBasketFill, RiAccountCircleLine, BsCreditCard, FiBox, RiPinDistanceLine, FaChevronDown, FaRegUserCircle, RiLogoutCircleRLine } from 'react-icons/all';

import logo from 'assets/image/logo.png';
import { logout } from 'api/auth-client';

import { Drawer } from './drawer';

export function Header() {

    const { t, i18n } = useTranslation();

    const token = cookies.get(cookieAuthKey);

    const location = useLocation();

    const {
        name,
        surname,
        isLoading
    } = useUser();

    const [drawer, setDrawer] = React.useState(false)

    const toggleDrawwer = () => {
        setDrawer(!drawer)
    }

    return (
        <div className="site-header site-header--menu-right bg-default py-7 py-lg-0 site-header--absolute site-header--sticky mobile-sticky-enable">
            <div className="container">
                <nav className="navbar site-navbar offcanvas-active navbar-expand-lg  px-0 py-0">
                    <div className="brand-logo">
                        <Link to="/">
                            <img src={logo} alt={t('slogan')} className="light-version-logo default-logo" />
                            <img src={logo} alt={t('slogan')} className="dark-version-logo" />
                        </Link>
                    </div>
                    <div className="collapse navbar-collapse" id="mobile-menu">
                        <div className="navbar-nav-wrapper">
                            <ul className="navbar-nav main-menu">
                                <li className={`nav-item ${location.pathname === '/about' ? 'active' : ''}`}>
                                    <Link to="/about" className="nav-link">{t('header:about')}</Link>
                                </li>
                                <li className={`nav-item ${location.pathname === '/plans' ? 'active' : ''}`}>
                                    <Link to="/plans" className="nav-link">{t('header:prices')}</Link>
                                </li>
                                <li className={`nav-item ${location.pathname === '/stores' ? 'active' : ''}`}>
                                    <Link to="/stores" className="nav-link">{t('header:stores')}</Link>
                                </li>
                                <li className={`nav-item ${location.pathname === '/contacts' ? 'active' : ''}`}>
                                    <Link to="/contacts" className="nav-link">{t('header:contacts')}</Link>
                                </li>
                            </ul>
                        </div>
                        <button className="d-block d-lg-none offcanvas-btn-close focus-reset" type="button" data-toggle="collapse" data-target="#mobile-menu" aria-controls="mobile-menu" aria-expanded="true" aria-label="Toggle navigation">
                            <i className="gr-cross-icon"></i>
                        </button>
                    </div>
                    <div className='header-btn-devider'>
                        {i18n.language !== 'az' ? (
                            <button
                                className='language-btn'
                                onClick={() => i18n.changeLanguage('az')}
                            >
                                Az
                            </button>
                        ) : null}
                        {i18n.language !== 'en' ? (
                            <button
                                className='language-btn'
                                onClick={() => i18n.changeLanguage('en')}
                            >
                                En
                            </button>
                        ) : null}
                        {i18n.language !== 'ru' ? (
                            <button
                                className='language-btn'
                                onClick={() => i18n.changeLanguage('ru')}
                            >
                                Ru
                            </button>
                        ) : null}
                    </div>
                    {token ? (
                        <div className="header-btn-devider ml-auto ml-lg-5 pl-2 d-none d-xs-flex align-items-center">
                            <div>
                                <div className="dropdown show-gr-dropdown py-5">
                                    <div className="proile media ml-7 flex-y-center text-main-purp" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {isLoading ? (
                                            <Spin size="small" className="purple-spinner" />
                                        ) : (
                                            <>
                                                <div>
                                                    <FaRegUserCircle className="mr-2" /> {name}{' '}{surname}
                                                </div>
                                                <FaChevronDown className="ml-6" />
                                            </>
                                        )}
                                    </div>
                                    <div className="dropdown-menu gr-menu-dropdown dropdown-right border-0 border-width-2 py-2 w-auto bg-default" aria-labelledby="dropdownMenuLink">
                                        <Link className="dropdown-item py-2 font-size-3 font-weight-semibold line-height-1p2 text-main-purp" to="/dashboard">
                                            <RiDashboard2Line className="mr-7" />
                                            {t('header:controlPanel')}
                                        </Link>
                                        <Link className="dropdown-item py-2 font-size-3 font-weight-semibold line-height-1p2 text-main-purp" to="/orders">
                                            <RiShoppingBasketFill className=" mr-7" />
                                            {t('header:orders')}
                                        </Link>
                                        <Link className="dropdown-item py-2 font-size-3 font-weight-semibold line-height-1p2 text-main-purp" to="/packages">
                                            <FiBox className=" mr-7" />
                                            {t('header:packages')}
                                        </Link>
                                        <Link className="dropdown-item py-2 font-size-3 font-weight-semibold line-height-1p2 text-main-purp" to="/wallet">
                                            <BsCreditCard className="mr-7" />
                                            {t('header:balance')}
                                        </Link>
                                        <Link className="dropdown-item py-2 font-size-3 font-weight-semibold line-height-1p2 text-main-purp" to="/addreses">
                                            <RiPinDistanceLine className="mr-7" />
                                            {t('header:locations')}
                                        </Link>
                                        <Link className="dropdown-item py-2 font-size-3 font-weight-semibold line-height-1p2 text-main-purp" to="/profile">
                                            <RiAccountCircleLine className="mr-7" />
                                            {t('header:profile')}
                                        </Link>
                                        <button className="dropdown-item py-2 text-red font-size-3 font-weight-semibold line-height-1p2" onClick={logout}>
                                            <RiLogoutCircleRLine className="mr-7" />
                                            {t('header:logout')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="header-btns header-btn-devider ml-auto pr-2 ml-lg-6 d-none d-xs-flex">
                            <Link to="/login" className="btn btn-transparent text-uppercase font-size-3 heading-default-color focus-reset">
                                {t('header:login')}
                            </Link>
                            <Link to="/register" className="btn btn-dark text-uppercase font-size-3">
                                {t('header:register')}
                            </Link>
                        </div>
                    )}
                    <button className="navbar-toggler btn-close-off-canvas  hamburger-icon border-0" type="button" onClick={() => toggleDrawwer()}>
                        <span className="hamburger hamburger--squeeze js-hamburger">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </span>
                    </button>

                    <Drawer show={drawer} toggler={setDrawer} />
                </nav>
            </div>
        </div>
    )
}