import React from 'react'
import { InvoiceModal } from 'components';
import { useTranslation } from 'react-i18next';
import { textColors, bgColors, orderStatuses, orderPaymentStatus } from 'utils';
import { Skeleton, Popover, Tooltip } from 'antd';
import moment from 'moment';
import { RiAccountCircleLine, RiMessage2Line } from 'react-icons/ri';

import turkeyFlag from 'assets/flags/turkey.png';

export function WhiteCard({ icon, name, header, rounded, textClr = 'blue', bgClr = 'blue', isLoading }) {
    return (
        <div className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8">
            <Skeleton loading={isLoading} active avatar>
                <div className={`${textColors[textClr]} ${bgColors[bgClr]} circle-56 font-size-6 mr-7 hidden-xxs`}>
                    {icon}
                </div>
                <div className="">
                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                        <span className="counter">{header}</span>
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray mb-0">{name}</p>
                </div>
            </Skeleton>
        </div>
    )
}

export function AdvancedCard() {
    return (
        <div className="pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3">
            <div className="media align-items-center">
                <div className="square-52 bg-indigo mr-8 rounded">
                    <a href="/">
                        <img src="./image/l3/png/fimize.png" alt="" />
                    </a>
                </div>
                <div>
                    <a href="/" className="font-size-3 text-default-color line-height-2">Fimize</a>
                    <h3 className="font-size-6 mb-0">
                        <a className="heading-default-color font-weight-semibold" href="/">Senior Marketing</a>
                    </h3>
                </div>
            </div>
            <div className="d-flex pt-17">
                <ul className="list-unstyled mb-1 d-flex flex-wrap">
                    <li>
                        <a href="/" className="bg-regent-opacity-15 text-denim font-size-3 rounded-3 min-width-px-100 px-3 flex-all-center mr-6 h-px-33 mt-4">
                            <i className="icon icon-pin-3 mr-2 font-weight-bold"></i> Ətraflı
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export function UserCard({ info, isLoading }) {
    const { t } = useTranslation()
    return (
        <div className="bg-white shadow-9 rounded-4">
            <div className="px-5 py-11 text-center">
                <RiAccountCircleLine className="mb-5 display-1 text-main-orng" />
                {isLoading ? (
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                        <Skeleton.Button active size='small' style={{ width: 150, height: 15, marginBottom: 10 }} />
                        <Skeleton.Button active size='small' style={{ width: 150, height: 15, marginBottom: 10 }} />
                        <Skeleton.Button active size='small' style={{ width: 150, height: 15, marginBottom: 10 }} />
                    </div>
                ) : (
                    <>
                        <h4 className="mb-2">
                            <a className="text-black-2 font-size-6 font-weight-semibold" href="/">{info.name} {info.surname}</a>
                        </h4>
                        <p className="font-size-4 mb-0">{t('cards:userCode')}</p>
                        <h5 className="font-size-4 font-weight-semibold mb-0">
                            #CH{info.id + 1000}
                        </h5>
                    </>
                )}
            </div>

        </div>
    )
}



export function OrdersCard({ data }) {

    const { t } = useTranslation()

    const { color,
        description,
        id,
        local_cargo_price,
        price,
        quantity,
        size,
        status,
        status_reason,
        url } = data;

    const parseShopName = url => {

    }

    const statusColors = {
        1: 'bg-violet-opacity-4',
        2: 'bg-spray-opacity-4',
        3: 'bg-yellow-opacity-4',
        4: 'bg-orange-opacity-4',
        5: 'bg-green-opacity-4',
        6: 'bg-red-opacity-4'
    }

    return (
        <div className="mb-8">
            <div className="pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3 ">

                <div className="row">
                    <div className="col-md-8">
                        <div className="media align-items-center">
                            <div>
                                <h3 className="mb-0">
                                    <a className="font-size-6 heading-default-color" href={url} target="_blank" rel="noreferrer">{t('cards:orders:link')}</a>
                                </h3>
                                <a href="/" className="font-size-4 text-default-color line-height-2">{t('cards:orders:seller')}</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 text-right pt-7 pt-md-5">
                        <div className="media justify-content-md-end">
                            <p className="font-weight-bold font-size-7 text-hit-gray mb-0">
                                <span className="text-black-2">{price}</span> TL
                                <div className="font-size-3 text-default-color line-height-2">{t('cards:orders:cargo')}: {local_cargo_price} TL</div>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row pt-8">
                    <div className="col-md-8">
                        <ul className="d-flex list-unstyled mr-n3 flex-wrap">
                            <li>
                                <Tooltip title="Ölçü">
                                    <span className="bg-regent-opacity-15 min-width-px-96 mr-3 text-center rounded-3 px-6 py-1 font-size-3 text-black-2 mt-2">
                                        {size}
                                    </span>
                                </Tooltip>
                            </li>
                            <li>
                                <Tooltip title="Rəng">
                                    <span className="bg-regent-opacity-15 min-width-px-96 mr-3 text-center rounded-3 px-6 py-1 font-size-3 text-black-2 mt-2">
                                        {color}
                                    </span>
                                </Tooltip>
                            </li>
                            <li>
                                <Tooltip title="Say">
                                    <span className="bg-regent-opacity-15 min-width-px-96 mr-3 text-center rounded-3 px-6 py-1 font-size-3 text-black-2 mt-2">
                                        {quantity}
                                    </span>
                                </Tooltip>
                            </li>
                            {description ? (
                                <li>
                                    <Popover content={description} title={false}>
                                        <span className="bg-regent-opacity-15 text-center circle-32 font-size-4 text-black mt-2">
                                            <RiMessage2Line />
                                        </span>
                                    </Popover>
                                </li>) : null}
                        </ul>
                    </div>
                    <div className="col-md-3">
                        {/* <Tooltip title={orderStatuses[status]}>
                            <span className="circle-32 bg-red-opacity-4 mt-1 mr-0 float-right"></span>
                        </Tooltip> */}
                        <a className="btn btn-danger text-uppercase btn-xs rounded-3" href="/" style={{
                            minWidth: '100px',
                            height: '33px',
                            marginTop: '6px'
                        }}>
                            {t('cards:orders:pay')}
                        </a>
                    </div>
                    <div className="col-md-1">
                        <Tooltip title={orderStatuses[status]}>
                            <span className={`${statusColors[status]} circle-32 mt-1 mr-0 float-right`}></span>
                        </Tooltip>
                    </div>
                </div>

            </div>
        </div>
    )
}

export function PackageCard({ data }) {

    const { t } = useTranslation()

    const { arrived_date, country_name, custom_status, description, id, product_price, product_type, seller_name, shipping_price, status, tracking_number, weight, file, invoice_price } = data;

    const statuses = {
        'waiting': t('cards:packages:statusWaiting'),
        'warehouse': t('cards:packages:statusWarehouse'),
        'plane': t('cards:packages:statusPlane'),
        'customs': t('cards:packages:statusCustoms'),
        'sorting': t('cards:packages:statusSorting'),
        'office': t('cards:packages:statusOffice')
    }

    const customStatuses = {
        'not-stated': t('cards:packages:statusNotStatet'),
        'stated': t('cards:packages:statusStated')
    }

    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <div className="bg-white px-8 pt-9 pb-7 rounded-4 mb-9 feature-cardOne-adjustments hover-shadow-3">
                <div className="row mb-10">
                    <div className="col-md-8">
                        <div className="media align-items-center">
                            <div>
                                <h3 className="mb-2">
                                    <a className="font-size-6 heading-default-color" href="/">{seller_name}</a>
                                </h3>
                                <span className="d-flex align-items-center font-size-4 mb-0 text-gray">
                                    <img src={turkeyFlag} className="mr-4" alt={country_name} />
                                    {country_name}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 text-right pt-7 pt-md-5">
                        <span className="bg-yellow-opacity-4 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mb-2">
                            {statuses[status]}
                        </span>
                    </div>
                </div>
                {/* <h2 className="mt-n4">
                    <a className="font-size-7 text-black-2 font-weight-bold mb-4" href="/">{product_type}</a>
                </h2> */}
                <div className="row pt-10 border-top border-width-1 border-default-color">
                    <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                        <div className="mb-8">
                            <p className="font-size-3 mb-1">{t('cards:packages:deliveryDate')}</p>
                            <h5 className="font-size-4 font-weight-semibold text-black-2">{moment(arrived_date).format('DD-MM-YYYY')}</h5>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                        <div className="mb-8">
                            <p className="font-size-3 mb-1">{t('cards:packages:tracking')}</p>
                            <h5 className="font-size-4 font-weight-semibold text-black-2">{tracking_number}</h5>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                        <div className="mb-8">
                            <p className="font-size-3 mb-1">{t('cards:packages:weight')}</p>
                            <h5 className="font-size-4 font-weight-semibold text-black-2">{weight / 1000} {t('cards:packages:weightCode')}</h5>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                        <div className="mb-8">
                            <p className="font-size-3 mb-1">{t('cards:packages:price')}</p>
                            <h5 className="font-size-4 font-weight-semibold text-black-2">{product_price} TL</h5>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                        <div className="mb-8">
                            <p className="font-size-3 mb-1">{t('cards:packages:delivery')}</p>
                            <h5 className="font-size-4 font-weight-semibold text-black-2">{shipping_price} $</h5>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                        <div className="mb-8">
                            <p className="font-size-3 mb-1">{t('cards:packages:category')}</p>
                            <h5 className="font-size-4 font-weight-semibold text-black-2">{product_type}</h5>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                        <div className="mb-8">
                            <p className="font-size-3 mb-1">{t('cards:packages:customs')}</p>
                            <h5 className="font-size-4">
                                <span className="bg-orange-2 h-px-33 text-white text-center flex-all-center rounded-3 font-size-3">
                                    {customStatuses[custom_status]}
                                </span>
                            </h5>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                        <div className="mb-8">
                            <p className="font-size-3 mb-1">{t('cards:packages:invoice')}</p>
                            <h5 className="font-size-4">
                                <button className="btn bg-casablanca h-px-33 text-white text-center flex-all-center rounded-3 font-size-3" type="button" onClick={() => setModalShow(true)}>
                                    {t('cards:packages:uploadInvoice')}
                                </button>
                            </h5>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-md-4 col-xs-6">
                        <div className="mb-8">
                            <p className="font-size-3 mb-1">{t('cards:packages:note')}</p>
                            <h5 className="font-size-4 font-weight-semibold text-black-2">{description || t('cards:packages:empty')}</h5>
                        </div>
                    </div>
                </div>
                <div className="card-btn-group justify-content-end border-top border-width-1 border-default-color pt-10">
                    <a className="btn btn-green text-uppercase btn-medium rounded-3" href="/">
                        {t('cards:packages:pay')}
                    </a>
                    <a className="btn bg-orange-2 text-white text-uppercase btn-medium rounded-3" href="/" target>
                        <i className="icon icon-bookmark-2 font-weight-bold mr-4 font-size-4"></i> {t('cards:packages:attent')}
                    </a>
                </div>
            </div>
            <InvoiceModal show={modalShow} setModalShow={setModalShow} packageId={id} />
        </>
    )
}