import React from 'react'
import { useTranslation } from 'react-i18next';


import RegisterIcon from 'assets/image/l2/register.png';
import DeliveryIcon from 'assets/image/l2/delivery.png';
import PackageIcon from 'assets/image/l2/package.png';

export function HowWorks() {
    const { t } = useTranslation()
    return (
        <div className="pt-11 pt-lg-20 pb-7 pb-lg-18">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-5">
                        <div className="text-center mb-8 mb-lg-18 px-xl-9 px-xxl-7">
                            <h2 className="font-size-9 mb-6">{t('howWorks:header')}</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center" data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
                    <div className="col-12 col-lg-4 col-md-6 col-sm-8 col-xs-8">
                        <div className="px-xl-7 px-xxl-12 pt-5 pb-3 pb-lg-9 text-center">
                            <div className="square-92 mx-auto mb-11">
                                <img src={RegisterIcon} className="" alt="" style={{ width: '140px' }} />
                            </div>
                            <div className="services-content">
                                <h3 className="font-size-6 mb-7">{t('howWorks:textOne')}</h3>
                                <p className="font-size-4 text-default-color">
                                    {t('howWorks:textTwo')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-6 col-sm-8 col-xs-8">
                        <div className="px-xl-7 px-xxl-12 pt-5 pb-3 pb-lg-9 text-center">
                            <div className="square-92 mx-auto mb-11">
                                <img src={PackageIcon} className="" alt="" style={{ width: '140px' }} />
                            </div>
                            <div className="services-content">
                                <h3 className="font-size-6 mb-7">{t('howWorks:textTree')}</h3>
                                <p className="font-size-4 text-default-color">
                                    {t('howWorks:textFour')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-6 col-sm-8 col-xs-8">
                        <div className="px-xl-7 px-xxl-12 pt-5 pb-3 pb-lg-9 text-center">
                            <div className="square-92 mx-auto mb-11">
                                <img src={DeliveryIcon} className="" alt="" style={{ width: '140px' }} />
                            </div>
                            <div className="services-content">
                                <h3 className="font-size-6 mb-7">{t('howWorks:textFive')}</h3>
                                <p className="font-size-4 text-default-color">
                                    {t('howWorks:textSix')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
