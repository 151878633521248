import React from 'react'
import styled from '@emotion/styled';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export function Loading() {
    const icon = <LoadingOutlined style={{ fontSize: 36 }} className="orange-spinner" spin />;
    return (
        <LoadingContainer>
            <Spin indicator={icon} />
        </LoadingContainer>
    )
}

const LoadingContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;
