import React from 'react'
import { Tooltip, message } from 'antd';
import styled from '@emotion/styled';
import { IoCopyOutline } from 'react-icons/io5';

export function Clipboard({ text }) {

    const copyToClipboard = text => {
        navigator.clipboard.writeText(text);
        message.success('Mətn kopyalandı');
    }

    return (
        <Tooltip title="Kopyala">
            <ClipboardContainer>
                <button type="button" onClick={() => copyToClipboard(text)}>
                    <IoCopyOutline />
                </button>
            </ClipboardContainer>
        </Tooltip>
    )
}

const ClipboardContainer = styled.div`
    display: inline-block;
    position: absolute;
    right: 35px;
    top: 24px;

    button {
        border: 0;
        background: transparent;
        margin: 0 0 0 10px;
        font-size: 20px;
    }
`;