import { useEffect } from 'react';
import { message } from 'antd';

import { apiErrorResolver } from 'utils';

export function useErrorHandler(error) {
  useEffect(() => {
    if (error) {
      const { errorMessage } = apiErrorResolver(error);

      let messageText = '';
      switch (errorMessage) {
        case 'invalid_credentials':
          messageText = 'Login və yaxud şifrəniz səhvdir.';
          break;
        default:
          break;
      }

      message.error(messageText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
}
