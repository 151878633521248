import { client, cookieAuthKey } from 'api';
import { cookies, windowLocationReplace } from 'utils';

export const resetCookies = () => {
  cookies.remove(cookieAuthKey);
  cookies.remove('dvc');
};

export function handleUserResponse(user, redirectUrl = '/dashboard') {
  const { accessToken, deviceToken } = user;

  if (accessToken) {
    cookies.set(cookieAuthKey, accessToken);
    cookies.set('dvc', deviceToken);
  }

  windowLocationReplace(redirectUrl);
}

function login([data]) {
  const device_token = cookies.get('dvc') || '';

  const body = {
    ...data,
    device_token,
  };

  resetCookies();

  return client('/login', { body });
}

function register([body]) {
  resetCookies();

  return client('/register', { body });
}

function sendRecoveryRequest([body]) {
  resetCookies();

  return client('/password/recovery', { body });
}

function resetPassword([body, token]) {
  resetCookies();

  return client(`/password/recovery/${token}`, { body });
}

function checkToken({ token }) {
  return client(`/password/recovery/${token}`);
}

function logout() {
  resetCookies();
  window.localStorage.setItem('logout', Date.now());

  windowLocationReplace('/login');
}

function redirectToPending() {
  resetCookies();
  window.localStorage.setItem('logout', Date.now());

  windowLocationReplace('/pending');
}

function getToken() {
  return cookies.get(cookieAuthKey);
}

export {
  login,
  register,
  logout,
  getToken,
  redirectToPending,
  sendRecoveryRequest,
  resetPassword,
  checkToken,
};
